import React from "react";
import Accordion from "../components/Accordion/Accordion";

export default function AccordionPage() {
  return (
    <div className="accordion-page page">
      <h1 className="accordion-page__title page-title">Accordion</h1>
      <div className="accordion-page__items page-items">
        <Accordion title={"I should write something here"}>
          <p>But I don't know what</p>
        </Accordion>
        <Accordion title={"Testing accordion"}>
          <p>I think I have something to write</p>
          <p>Oh no, it just seemed</p>
        </Accordion>
        <Accordion title={"There is something you should know"}>
          <p>You are the best ✨✨</p>
          <p>Thank you</p>
          <p>
            <a
              target="_blank"
              referrerPolicy="no-referrer"
              href="https://instagram.com/frontzavr?igshid=OGQ5ZDc2ODk2ZA=="
            >
              I'll be waiting here...
            </a>
          </p>
        </Accordion>
      </div>
    </div>
  );
}
