import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import AccordionStyle from "./Accordion.module.css";

interface AccordionProps {
  title: string;
  className?: {
    accordion?: string;
    title?: string;
    content?: string;
  };
}

export default function Accordion({
  children,
  title,
  className,
}: PropsWithChildren<AccordionProps>) {
  const ref = useRef<HTMLDivElement>(null);

  const [classes, setClasses] = useState({
    accordion: [AccordionStyle.accordion],
    title: [AccordionStyle.accordion__title],
    content: [AccordionStyle.accordion__content],
  });

  useEffect(() => {
    if (!!className) {
      for (const classNameKey in className) {
        if (classNameKey === "accordion") {
          setClasses((prevState) => ({
            ...prevState,
            accordion: [...prevState.accordion, className.accordion!],
          }));
        } else if (classNameKey === "title") {
          setClasses((prevState) => ({
            ...prevState,
            title: [...prevState.title, className.title!],
          }));
        } else if (classNameKey === "content") {
          setClasses((prevState) => ({
            ...prevState,
            content: [...prevState.content, className.content!],
          }));
        }
      }
    }
  }, [className]);

  const accordionClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    ref.current?.classList.toggle("active");
    const content = ref.current?.querySelector(
      `.${AccordionStyle.accordion__content}`
    );
    if (!!content) {
      if (content.hasAttribute("style")) {
        content.removeAttribute("style");
      } else {
        content.setAttribute("style", `max-height:${content.scrollHeight}px`);
      }
    }
  };

  return (
    <div className={classes.accordion.join(" ")} ref={ref}>
      <button
        onClick={accordionClickHandler}
        className={classes.title.join(" ")}
      >
        {title}
      </button>
      <div className={classes.content.join(" ")}>{children}</div>
    </div>
  );
}
