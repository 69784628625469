import React from "react";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AccordionPage from "./routes/AccordionPage";

export default function App() {
  const router = createBrowserRouter([
    {
      path: "accordion",
      element: <AccordionPage />,
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}
